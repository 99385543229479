@import "base/variables";

body {
	background-color: $white;
}

#page-header {
	position: relative;
	height: auto;
	background: #fff;
	padding: 2rem 4rem;
	text-align: center;
	border-bottom: 1px solid $enb-grey;
	
	.flex {
		display: block;
	}
	
	.logo {
		width: 100%;
		
		a:after {
			display:none;
		}
	}
	
	.site-links {
		display: none;
	}
}

#main {
	padding: 0;
	min-height: 0;
	
	.banner {
		background: none !important;
		height: auto;
		margin: 0;
		
		&.short {
			height: auto;
			
			.banner-content {
				.banner-text {
					padding: 0;
				}
			}
		}
		
		.banner-content {
			position: relative;
			top: 0;
			-webkit-transform: none;
			-ms-transform: none;
			transform: none;
			
			.banner-text {
				width: 100%;
				color: #000;
				text-shadow: none;
				padding: 0;
				
				h1 {
					font-size: 2rem;
					color: #000;
				}
				
				p {
					font-size: 1rem;
				}
				
				strong {
					color: #000;
				}
			}
		} 
	}
	
	.content {
		padding: 0;
		
		&.tiles {
			padding: 0;
			margin-left: 0;
			margin-right: 0;
			
			.banner-tile-alt {
				width: 100%
			}
			
			.tile {
				background: none !important;
				border: 1px solid $enb-grey;
				height: 200px;
				
				.container, a.container {
					.tile-content {
						font-size: 1rem;
						color: #000;
						text-shadow: none;
						padding: .5rem 1rem;
					}
					
					.tile-cta {
						background: none;
						border-bottom: 1px solid $enb-grey;
						color: #000;
						text-shadow: none;
						
						.plus {
							display: none;
						}
					}
					
				}
				
				&.large-8 {
					.container, a.container {
						.tile-cta {
							font-size: 1.2rem;
						}
					}
				}
				
				&.large-6 {
					.container, a.container {
						.tile-content {
							line-height: 1.4rem;
						}
						.tile-cta {
							font-size: 1rem;
						}
					}
				}
			}
		}
	}
}
#page-footer {
	background: #fff;
	padding: 2rem;
	text-align: center;
	color: #000;
	
	.scroll-to-top, .footer-links {
		display: none;
	}
	
}