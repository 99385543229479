// Enbridge Brand Colours
// - - - - - - - - - - - - - - - - - - - - - - - - -
$enb-yellow: 		#ffb81c;
$enb-charcoal: 		#353535;
$enb-grey: 			#555555;
$enb-steel: 		#d0d0ce;
$enb-light-grey: 	#eaeaed;
$enb-soft-yellow: 	#fff6e3;
$enb-cherry: 		#c8102e;
$enb-mint:          #32dac4;

// Other colours
// - - - - - - - - - - - - - - - - - - - - - - - - -
$body-copy:			#1a1a1a;
$white: 			#ffffff;
$black: 			#000000;
$grey-b4: 			#b4b4b4;
$grey-7f:			#7f7f7f;
$grey-ccc: 			#cccccc;
$grey-f2:           #f2f2f2;
$grey-e8:			#e8e8e8;
$green:				#00c443;

// Font families
// - - - - - - - - - - - - - - - - - - - - - - - - -
$text-black: "fs-black", Arial, sans-serif;
$text-black-italic: "fs-black-italic", Arial, sans-serif;
$text-bold: "fs-bold", Arial, sans-serif;
$text-bold-italic: "fs-bold-italic", Arial, sans-serif;
$text-italic: "fs-italic", Arial, sans-serif;
$text-light: "fs-light", Arial, sans-serif;
$text-light-italic: "fs-light-italic", Arial, sans-serif;
$text-roman: "fs-roman", Arial, sans-serif;

// Page scaling variables
// - - - - - - - - - - - - - - - - - - - - - - - - -
$max-content-size:			1400px;


